import React from 'react';
import possibilityImage from '../../assets/possibility.png';
import './possibility.css';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content">
      <h4>Request Early Access to the Worldwide Skiing Guide</h4>
      <h1 className="gradient__text">More World Class Skiing Venues <br /> are constantly emerging</h1>
      <p>There has never been a better time to become involved in the exciting world of Skiing</p>
      <h4>Request Early Access to the worldwide Skiing Guide</h4>
    </div>
  </div>
);

export default Possibility;
