import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="Where are the best places in the world to go skiing?" text="From the incredible Portes du Soleil ski area in France to the Dolomites ski area in Italy, there really are some incredible ski areas in the world. Our ski experts are here to help you select the ideal ski area for your groups needs. Whether you are looking for extensive ski terrain or perhaps one with the finest scenery we can help. Here’s our pick of the biggest and best ski areas in the world. These world-class ski resorts are not only good places to go skiing, they deliver a 5-star experience off the slopes too. We take into consideration après ski, on and off-piste skiing, accommodation, restaurants and mountain views. Take a look at the most highly regarded ski resorts on the planet!" />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">From Stunning Views, To Wonderful Hotels, the possibilities are endless</h1>
      <p>Explore the Worldwide Skiing Guide</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="1. Three Valleys, France" text="The Three Valleys is the largest ski area in the world. Courchevel, Méribel and Val Thorens are just three of the eight world-class ski resorts packed into this one ski area. It’s a great choice if your ski party is made up of different skiing personalities and levels." />
      <Feature title="2. Portes du Soleil, France" text="Portes du Soleil literally means ‘doors of the sun’ and has some of the top ski resorts in the world. Many popular ski resorts such as Avoriaz and Morzine are situated here. The ski area hosts a popular annual free concert, ‘Rock the Pistes’. You’ll enjoy some of the best French and international pop and rock here." />
      <Feature title="3. Espace Killy, France" text="One of the biggest ski areas in the world, the Espace Killy has two ski resorts Tignes and Val d’Isère. Both offer excellent skiing that’s naturally linked so there’s no need to waste valuable snow time on lifts or connecting trails. Thanks to its high altitude, the area has one of the longest seasons in the Alps, running from November to May." />
    </div>
  </div>
);

export default WhatGPT3;
