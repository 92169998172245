import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: '4. Paradiski, France',
    text: 'One of the biggest ski areas in the world, Paradiski is in the Tarentaise valley. This ski area includes the ski resorts Les Arcs, La Plagne and Peisey-Vallandry. The ski area is home to 425km of piste, with plenty of variety for even the most experienced skier. Skiing is possible up to an altitude of 3250m, ensuring good snow conditions throughout the season.',
  },
  {
    title: '5. Dolomites, Italy',
    text: 'The Dolomites ski area is one of the most best places to ski in the world. The ski area is made up of 12 ski resorts with over 1,200km of pistes between them, backed by the most jaw-dropping of landscapes. The Dolomites is a UNESCO World Heritage Site, famed for its pale mountains and unique rock formations.',
  },
  {
    title: '6. Aosta Valley, Italy',
    text: 'The Aosta Valley ski area is home to three of Europe’s highest peaks: Mont Blanc, the Matterhorn and Monte Rosa. These towering mountains offer high-altitude skiing, snow-sure slopes and provide a stunning backdrop to any ski day.',
  },
  {
    title: '7. Milky Way, Italy',
    text: 'The Milky Way ski area sits on the French-Italian border and boasts over 400km of pistes between a number of linked ski resorts. The ski area played host to the 2006 Winter Olympics which brought investment and speedier lifts to the area.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">The Future is Skiing - Step into an exciting Future</h1>
      <p>Request Early Access to the WorldWide Viewing Guide</p>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
