import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import './blog.css';

const Blog = () => (
  <div className="gpt3__blog section__padding" id="blog">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">A lot is happening, <br /> We are blogging about it.</h1>
    </div>
    <div className="gpt3__blog-container">
      <div className="gpt3__blog-container_groupA">
        <Article imgUrl={blog01} date="Sep 26, 2022" text="Alpincenter, Germany Home to the world’s longest indoor ski slope, the Alpincenter in Bottrop has a dedicated beginners’ area and a 640m-long main slope, as well as a huge half-pipe" />
      </div>
      <div className="gpt3__blog-container_groupB">
        <Article imgUrl={blog02} date="Feb 02, 2023" text="Jamie Barrow Breaks The World Record For Fastest Speed On A Snowboard At 211kph (131.11mph)" />
        <Article imgUrl={blog03} date="Aug 09, 2022" text="Tignes & Val d’Isere The Espace Killy ski area is rated the best snowboarding terrain in Europe" />
        <Article imgUrl={blog04} date="Sep 12, 2022" text="Val Thorens Ski School is nestled in the Three Valleys, the largest ski area in the world sitting at 2300m" />
        <Article imgUrl={blog05} date="Jun 16, 2022" text="Monte Kaolino, Germany combines the best of both worlds – the feel of a sand dune (although it’s actually a by-product of kaolinite production, which is mined nearby) with a mountain-like layout" />
      </div>
    </div>
  </div>
);

export default Blog;
